export const onInitialClientRender = () => {
  if (process.env.NODE_ENV === "production") {
    // Adicionando o script do Tawk.to
    const tawkScript = document.createElement("script");
    tawkScript.type = "text/javascript";
    tawkScript.async = true;
    tawkScript.src = "https://embed.tawk.to/677f088149e2fd8dfe0487c9/1ih43if0b";
    tawkScript.charset = "UTF-8";
    tawkScript.setAttribute("crossorigin", "*");

    const firstScript = document.getElementsByTagName("script")[0];
    firstScript.parentNode.insertBefore(tawkScript, firstScript);

    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    // Adicionando o script do Hotjar
    const hotjarScript = document.createElement("script");
    hotjarScript.type = "text/javascript";
    hotjarScript.async = true;
    hotjarScript.src = "https://static.hj.contentsquare.net/c/csq-5266085.js";

    const headElement = document.getElementsByTagName("head")[0];
    headElement.appendChild(hotjarScript);

    window.hj =
      window.hj ||
      function () {
        (window.hj.q = window.hj.q || []).push(arguments);
      };
    window._hjSettings = { hjid: 5266085 };
  } else {
    console.log("Ambiente de desenvolvimento: scripts não carregados.");
  }
};
